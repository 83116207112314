import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../../components/layout/Layout.component";
import Author from "../../../components/author/Author.component";
import * as style from "../Project.style.module.scss";

const Achilles = () => {
  return (
    <Layout>
      <StaticImage
        src="./images/markus-spiske-1LLh8k2_YFk-unsplash.jpg"
        as="header"
        className={style.hero}
      />
      <main className={style.post}>
        <article className={style.article}>
          <div className={style.date}>Project</div>
          <h1>Achilles</h1>
          <h2>Introduction</h2>
          <p>
            I was given the task of creating a website for a moving company. I
            was also supposed to include elements of rallying there, because the
            company actively supports racing.
          </p>
          <h2>Sketch</h2>
          <StaticImage src="./images/flow.png" as="div" />
          <h2>Result</h2>
          <StaticImage src="./images/screenshot.png" as="div" />
        </article>
        <aside>
          <Author />
        </aside>
      </main>
    </Layout>
  );
};
export default Achilles;

export function Head() {
  return <title>Achilles</title>;
}
